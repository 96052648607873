import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import CompanyPhoto from '../../images/company-photo-2.svg'

class ImageModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      caption: this.props.caption ?? '-',
      imageLink: this.props.imageLink ?? ''
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageLink !== this.props.imageLink) {
      this.updateAndNotify(this.props.imageLink, this.props.caption);
    }
  }

  updateAndNotify(link, caption){
    this.setState({ imageLink: link, caption });
  }

  render () {
    return (
      <Modal
        {...this.props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        className='text-dark medium-font'
        contentClassName='bg-transparent no-border'
        centered
      >
        <Modal.Body className='bg-white rounded-lg-modal p-0'>
          <div className='container-fluid p-0'>
            <div className='row mx-0'>
              <div className='col-md-8 col-sm-12 d-md-block p-0'>
                <img src={this.state.imageLink} alt='company logo' className='img-fluid company-modal company-modal-overlay' />
              </div>
              <div className='col-md-4 col-sm-12 p-0'>
                <div class='d-flex align-items-start flex-column mb-3 h-100'>
                  <div>
                    <p className='px-3'>
                      <h6 className='pt-3'><b>Life at The Company</b></h6>
                      <hr />
                      {this.state.caption}
                    </p>
                  </div>
                  <div class='mt-auto w-100 py-4'>
                    <div className='row mx-0 justify-content-center'>
                      <div className='col-6'>
                        <button onClick={()=>this.props.setPrev()} className='normal-font form-control btn btn-recommendation-reverse rounded-pill'>Previous</button>
                      </div>
                      <div className='col-6'>
                        <button onClick={()=>this.props.setNext()} className='normal-font form-control btn btn-primary rounded-pill'>Next</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ImageModal
