import { Link, withRouter } from 'react-router-dom'
import React, { Component } from 'react'
import 'react-multi-carousel/lib/styles.css'
import InfiniteScroll from 'react-infinite-scroll-component'
import HomeLanding from '../../components/layouts/HomeLanding'
import { ReactComponent as IcStar } from '../../images/star.svg'
import { ReactComponent as IcClock } from '../../images/clock.svg'
import { ReactComponent as IcCase } from '../../images/suitcase.svg'
import { ReactComponent as IcCompany } from '../../images/company.svg'
import { ReactComponent as IcShare } from '../../images/share.svg'
import { ReactComponent as IcRock } from '../../images/rock.svg'
import { ReactComponent as IcReply } from '../../images/reply.svg'
import { ReactComponent as IcOption } from '../../images/option.svg'
import User from '../../images/user-2.svg'
import DefaultUser from '../../images/default-picture.svg'
import JobListingModal from '../../components/modals/JobListingModal'
import { ShareModal } from '../../components'
import { connect } from 'react-redux'
import { jobList, getJobDetails, applyJob } from '../../actions'
import PropTypes from 'prop-types'
import { GET_JOB } from '../../actions/responseTypes'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { currency, capitalize } from '../../utils/stringHelpers'
import { alertErrorValidation } from '../../utils'
import _ from 'lodash'
import Select from 'react-select/async'
import axios from 'axios'

const filterSearch = async (inputValue) => {
  const token = localStorage.jwtToken
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_API}/v1/master/location?q=${inputValue}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })

    const optionLocation = await res.data.map(option => {
      return {
        value: option.id,
        label: option.name,
      }
    })

    return optionLocation
  } catch (e) {
    console.log(e)
  }
}

const promiseOptions = inputValue =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(filterSearch(inputValue));
    }, 1000);
  });

class JobDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      items: [],
      page: 1,
      maxPage: 1,
      modalShow: false,
      modalShare: false,
      isLoading: false,
      isLoadingDetail: false,
      jobDetail: {},
      totalJobs: 0,
      showListJob: "1-10",
      selectedJob: null,
      selectedLocation: '',
      hasMore: false,
      search: '',
    }
    this.fetchMoreData = this.fetchMoreData.bind(this)
  }

  fetchMoreData () {
    this.setState({ page: (this.state.page + 1) }, () => {
      this.props.jobList(this.state.page, this.state.search)
    })
    if(this.state.items.length === 0) {
      this.setState({hasMore: false})
    } else {
      this.setState({hasMore: true})
    }
  };

  componentDidMount = async () => {
    this.setState({isLoading: true})
    await this.props.jobList(1, this.state.search)
    await this.props.getJobDetails(this.props.match.params.id)
    const res = this.props.response
    if(res) {
      this.setState({jobDetail: res.data})
    }
    this.setState({isLoading: false, selectedJob: this.props.match.params.id})
  }

  handleModalCallback = (isModalShow) => {
    this.setState({modalShow: isModalShow})
  }

  formatMoney = amount => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  onJobClick = async (item, index) => {
    this.setState({selectedJob: item.uuid})
    this.setState({isLoadingDetail: true})
    await this.props.getJobDetails(item.uuid)
    const res = this.props.response
    
    if(res) {
      this.props.history.push(`/jobs/${item.uuid}`)
      this.setState({jobDetail: res.data})
    }
    this.setState({isLoadingDetail: false})
  }

  fetchSearch = async () => {
    this.setState({isLoading: true})
    this.setState({items: []})
    await this.props.jobList(1, this.state.search, this.state.selectedLocation)

    if(this.state.items.length !== 0) {
      await this.props.getJobDetails(this.state.items[0].uuid)
      const res = this.props.response
      if(res) {
        this.props.history.push(`/jobs/${this.state.items[0].uuid}`)
        this.setState({jobDetail: res.data})
      }
    }
   
    this.setState({isLoading: false})
  }

  handleApply = async (item) => {
    if (this.props.thisUser.is_complete) {
      if (item.has_quiz.quiz) {
        this.props.history.push(`/jobs/quiz/${item.uuid}`)
      } else {
        await this.props.applyJob(item.uuid);
        toast.success('You have successfuly applied')
        this.setState({isLoading: true})
        await this.props.getJobDetails(this.props.match.params.id)
        const res = this.props.response
        if(res) {
          this.setState({jobDetail: res.data})
        }
        this.setState({isLoading: false})
      }
    } else {
      this.setState({modalShow: true})
    }
  }
  
  componentDidUpdate = async (prevProps) => {
    if (prevProps.error !== this.props.error && this.props.error) {
      alertErrorValidation(this.props.error)
      this.setState({loading: false})
    }
    if (this.props.response && prevProps.response !== this.props.response) {
      switch (this.props.response.type) {
        case GET_JOB:
          this.setState(prevState => ({
            maxPage: this.props.response.data.last_page,
            items: [
              ...prevState.items,
              ...this.props.response.data.data
            ],
          }))
          {this.props.response.data.data.length !== 0 ? this.setState({totalJobs: this.props.response.data.total}) : this.setState({totalJobs: 0})}
          {this.props.response.data.data.length !== 0 ? this.setState({showListJob: this.props.response.data.from !== null && this.props.response.data.from + "-" + this.props.response.data.to}) : this.setState({showListJob: '0-0'})}
          break;
        default:
          break;
      }
    }
  }

  selectedValue = (location) => {
    this.setState({selectedLocation: location.value})
  }

  render () {
    const { jobDetail } = this.state

    return (
      <HomeLanding search={false}>
        <div className='container-padding'>
          <div className='card bg-white border-none text-left pb-0 mb-3 rounded-lg d-none d-lg-block'>
            <div className='card-body text-center'>
              <h5><b>Search for your next job</b></h5>
              <div className='d-flex pt-3'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <input onChange={(e) => {this.setState({search : e.target.value})}} type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='Search by title, skill or company' />
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <Select placeholder="Search by city or province" className="select-dropdown-wrapper" cacheOptions defaultOptions loadOptions={promiseOptions} onChange={this.selectedValue}  classNamePrefix="select-dropdown"/>
                  </div>
                  {/* <input type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='DKI Jakarta' /> */}
                </div>
                <div className='col-md-2'>
                  <button type='submit' onClick={this.fetchSearch} className='normal-font py-4 form-control px-5 btn btn-primary rounded-pill'>Search</button>
                </div>
              </div>
            </div>
          </div>
          <div className='d-none d-lg-flex'>
            <div className='p-2 font-weight-medium'>{this.state.showListJob} of {this.state.totalJobs} job matches</div>
            <div className='p-2 flex-grow-1'><hr /></div>
          </div>
          <div className='row pb-4'>
            <div className='col-md-5 d-none d-lg-block'>
              <div className='card bg-white rounded-lg'>
                <div className='card-header'>
                  <h6 className='pt-2'><b>Job Listing </b></h6>
                </div>
                <div className='card-body'>
                  {/* INFINITE SCROLL */}
                  <InfiniteScroll
                    dataLength={this.state.items.length}
                    next={this.fetchMoreData}
                    hasMore={this.hasMore}
                    height={500}
                    loader={
                      <div className='d-flex align-item-center pb-4 justify-content-center'>
                        <span className='medium-font text-muted'>Loading...</span>
                      </div>
                    }
                  > 
                    {this.state.isLoading ? <div className="d-flex justify-content-center">...Loading</div> : 
                    this.state.items.length === 0 && !this.state.isLoading  ? <div className="d-flex align-self-center justify-content-center ">No Data</div> : 
                    this.state.items.map((i, index) => (
                      <div key={`jobs-list-${i.uuid}`} className='card bg-job-listing border-none text-left pb-0 mb-3 rounded-lg'>
                        <div onClick={(e) => {this.onJobClick(i, index)}} id={i.uuid} key={index} className={this.state.selectedJob === i.uuid ? `card-body active-job` : `card-body`}>
                          <div className='row'>
                            {/* <div className='col-3 pr-0'>
                              <IcCompany className='avatar-sm rounded-circle' />
                            </div> */}
                            <div className='col-12'>
                              <div className='d-flex align-content-between flex-wrap'>
                                <div className='full-width'>
                                  <span className='normal-font font-weight-medium'>
                                  {i.title}, {i.company_detail.company_name}
                                  </span>
                                  <p className='normal-font text-muted'>{i.company_detail.company_name} - {capitalize(i.company_detail.province_detail.name)}, ID</p>
                                </div>
                                <div className='full-width'>
                                  <hr className='mb-0' />
                                  <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                                    <p className='normal-font text-muted mb-0'>Posted {i?.created_at_detail?.human} - {i?.people_applied} people applied</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                </div>
              </div>
              <br/>
            </div>
            <div className='col-md-7'>
              <div className='card bg-white rounded-lg normal-font'>
                <div className='card-header bg-transparent'>
                  <span>
                    <div className='d-flex align-items-center'>
                      {/* <div className='p-2'>
                        <IcCompany className='avatar-lg rounded-circle' />
                      </div> */}
                      <div className='p-2 flex-grow'>
                        <div className='d-flex align-content-between flex-wrap'>
                          <div className='full-width'>
                            <span className='medium-font font-weight-medium'>
                              {jobDetail?.title}
                            </span>
                            {jobDetail?.sallary_min && jobDetail?.sallary_min ? <p className='normal-font'>Estimated Sallary {' IDR '} <span className='color-salary font-weight-medium'>{this.formatMoney(jobDetail?.sallary_min ?? '')} - {this.formatMoney(jobDetail?.sallary_max ?? '')}</span></p> : null}
                            <p className='normal-font text-muted'>{jobDetail?.company_detail?.company_name} - {" "}
                            {_.startCase(
                                _.toLower(jobDetail?.company_detail?.province_detail?.name ?? '-'),
                              )}
                            , {jobDetail?.company_detail?.country}</p>
                          </div>
                          <div className='full-width'>
                            <div className='d-flex'>
                              {
                                Number(jobDetail?.created_by)!==Number(this.props.thisUser.id) && (
                                  <div className=''>
                                    <button onClick={() => this.handleApply(jobDetail)} className='normal-font py-2 px-3 btn btn-primary rounded-pill mr-2'>Apply Now</button>
                                  </div>
                                )
                              }
                              <div className=''>
                                {/* <button className='normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill'>Save Job</button> */}
                              </div>
                              <div className='flex-grow-1 justify-content-right'>
                                <button onClick={() => this.setState({ modalShare: true })} className='d-inline-block float-right normal-font py-2 px-3 btn btn-recommendation-reverse rounded-pill'><IcShare className='hc-ic mr-2' /> Share</button>
                              </div>
                            </div>
                            <div className='d-flex py-1 text-left normal-font align-items-center justify-content-start'>
                            {/* i?.created_at_detail?.human */}
                              <p className='normal-font text-muted mb-0'>Posted {this.state.items.length > 0 && this.state.items.find((f)=>f.uuid===this.state.selectedJob)?.created_at_detail?.human}  - {jobDetail?.people_applied} people applied</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                <ul className='list-group list-group-flush'>
                  <li className='list-group-item'>
                    <span className='font-weight-medium'>Company Highlight</span>
                    <br /><br />
                    <span>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <span>{jobDetail?.company_detail?.about} </span>
                          <br /><br />
                          { jobDetail?.company_detail?.number_of_employee_detail ?
                         <div> Employee : <b>{jobDetail?.company_detail?.number_of_employee_detail?.range} employees</b></div> :null}
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <div className='d-flex pb-1 text-left align-items-center justify-content-start'>
                            <IcStar className='hc-small-ic mr-2 svg-black' /> {jobDetail?.job_level_detail?.level}
                          </div>
                          <div className='d-flex py-1 text-left align-items-center justify-content-start'>
                            <IcClock className='hc-small-ic mr-2 svg-black' /> {jobDetail?.job_type_detail?.type}
                          </div>
                          <div className='d-flex py-1 text-left align-items-center justify-content-start'>
                            <IcCase className='hc-small-ic mr-2 svg-black' /> {jobDetail?.job_role_detail?.job_role}
                          </div>
                        </div>
                      </div>
                    </span>
                  </li>
                  <li className='list-group-item'>
                    <span className='font-weight-medium'>Job Description</span>
                    <br /><br />
                    <span>{jobDetail?.description}</span>
                    <br /><br />
                    <span className='font-weight-medium'>Key Responsibilities:</span>
                    <ul className='list-disc pl-2 ml-1'>
                      {jobDetail?.key_responsibilitis}
                      {/* <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li> */}
                    </ul>
                    <br />
                    <span className='font-weight-medium'>Requirements:</span>
                    <ul className='list-disc pl-2 ml-1'>
                      {jobDetail?.requirements}
                      {/* <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li> */}
                    </ul>
                  </li>
                </ul>

                <div className='card-body'>
                  <span className='font-weight-medium'>Benefit:</span>
                  <ul className='list-disc pl-2 ml-1'>
                    {jobDetail?.benefit}
                    {/* <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li> */}
                  </ul>
                </div>
              </div>

              {/* <div className='card bg-white rounded-lg mt-3'>
                <div className='card-body'>
                  <div className='d-flex'>
                    <div className='flex-grow-1'>
                      <input type='text' className='p-4 form-control rounded-pill custom-textfield' placeholder='Any question? Let’s start discuss with others job seekers or admin' />
                    </div>
                    <div className='ml-2'>
                      <Link to='/' className='normal-font py-4 form-control px-3 btn btn-primary rounded-pill'>Comment</Link>
                    </div>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='p-2 flex-grow-1'><hr /></div>
                    <div className='p-2 normal-font'>
                      <div className='dropdown'>
                        Sort by :
                        <select className='form-select select-simple font-weight-medium' aria-label='Default select example'>
                          <option selected>Top</option>
                          <option value='1'>Latest</option>
                          <option value='2'>Oldest</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='card bg-white rounded-lg normal-font'>

                    <ul className='list-group list-group-flush card-body p-0 rounded-lg'>
                      <li className='list-group-item bg-primary-reply'>
                        <div className='dropdown-hover option-float-right float-right'>
                          <Link
                            className='medium-font font-weight-medium d-flex align-items-center justify-content-center' to='#' id='navbarDropdown' role='button'
                            data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'
                          >
                            <IcOption className='hc-ic' />
                          </Link>
                          <div className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
                            <Link className='dropdown-item' to='#'>Report this comment</Link>
                            <Link className='dropdown-item' to='#'>Report user</Link>
                            <div className='dropdown-divider' />
                            <Link className='dropdown-item' to='#'>View user profile</Link>
                          </div>
                        </div>
                        <div className='d-flex row align-items-start'>
                          <div className='col-lg-2 col-xs-12'>
                            <img src={User} className='avatar-sm rounded-circle' alt='icon' />
                          </div>
                          <div className='col-lg-10 col-xs-12'>
                            <div className='d-flex align-content-between flex-wrap'>
                              <div className='full-width'>
                                
                                <span className='medium-font font-weight-medium'>
                                  Fanisha Arafah
                                </span>
                                <br />
                                <span className='normal-font'>UI / UX Designer</span>
                                <p className='normal-font text-muted'>5 hours ago</p>
                                <p className='normal-font'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                              </div>
                              <div className='full-width'>
                                <div className='d-flex'>
                                  <div className=''>
                                    <div className='nav-link pl-0 normal-font font-weight-medium color-navbar d-flex align-items-center justify-content-center'>
                                      <IcRock className='mr-1' /> Rock
                                    </div>
                                  </div>
                                  <div className=''>
                                    <div className='nav-link normal-font font-weight-medium color-navbar d-flex align-items-center justify-content-center'>
                                      <IcReply className='mr-1' /> Reply
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li className='list-group-item bg-secondary-reply'>
                        <div className='dropdown-hover option-float-right float-right'>
                          <Link
                            className='medium-font font-weight-medium d-flex align-items-center justify-content-center' to='#' id='navbarDropdown' role='button'
                            data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'
                          >
                            <IcOption className='hc-ic' />
                          </Link>
                          <div className='dropdown-menu dropdown-menu-right' aria-labelledby='navbarDropdown'>
                            <Link className='dropdown-item' to='#'>Report this comment</Link>
                            <Link className='dropdown-item' to='#'>Report user</Link>
                            <div className='dropdown-divider' />
                            <Link className='dropdown-item' to='#'>View user profile</Link>
                          </div>
                        </div>
                        <div className='d-flex row align-items-start'>
                          <div className='col-lg-2 col-xs-12 offset-lg-2'>
                            <img src={DefaultUser} className='avatar-sm rounded-circle' alt='icon' />
                          </div>
                          <div className='col-lg-8 col-xs-12'>
                            <div className='d-flex align-content-between flex-wrap'>
                              <div className='full-width'>
                                <span className='medium-font font-weight-medium'>
                                  Mr. Rockers
                                </span>
                                <br />
                                <span className='normal-font'>UI / UX Designer</span>
                                <p className='normal-font text-muted'>5 hours ago</p>
                                <p className='normal-font'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                              </div>
                              <div className='full-width mb-2'>
                                <div className='d-flex'>
                                  <div className=''>
                                    <div className='nav-link pl-0 normal-font font-weight-medium color-navbar d-flex align-items-center justify-content-center'>
                                      <IcRock className='mr-1' /> Rock
                                    </div>
                                  </div>
                                  <div className=''>
                                    <div className='nav-link normal-font font-weight-medium color-navbar d-flex align-items-center justify-content-center'>
                                      <IcReply className='mr-1' /> Reply
                                    </div>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                          <div className='col-12'>
                            <div className="row">
                              <div className='col-lg-9 col-xs-12'>
                                <input type='text' className='mb-2 p-4 form-control rounded-pill custom-textfield-white' placeholder='Write a comment' />
                              </div>
                              <div className='col-lg-3 col-xs-12'>
                                <Link to='/' className='mb-2 normal-font py-4 form-control px-5 btn btn-primary rounded-pill'>Reply</Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
             */}
            </div>
          </div>
        </div>
        <JobListingModal
          show={this.state.modalShow}
          desc='You cannot apply to this job vacancy before completing your CV'
          hrefButton={["profile/edit"]}
          titlebutton={['Complete a CV Now']}
          onHide={() => this.setState({ modalShow: false })}
          modalCallback={this.handleModalCallback}
        />
        <ShareModal
          jobDetail = {jobDetail}
          show={this.state.modalShare}
          onHide={() => this.setState({ modalShare: false })}
        />
      </HomeLanding>
    )
  }
}

JobDetails.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  // console.log(state.thisUser);
  return{
    auth: state.auth,
    thisUser: state.thisUser,
    error: state.error,
    invalid: state.invalid,
    response: state.response
  }
}

export default withRouter(connect(mapStateToProps, { jobList, getJobDetails, applyJob })(JobDetails))
