import React from "react";
import styled from "styled-components";
import IcNotFound from '../images/404.svg'
import { useHistory } from 'react-router-dom';

const Wrapper = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;1,400&display=swap');
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  // padding-top: 100px;
  @media only screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Col = styled.div`
  h1{
    /* Page Not Found */
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
    /* identical to box height */
    color: #333333;
  }
  p{
    /* Sorry, we can’t find the page you’re looking for. */
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      /* identical to box height */
      color: #333333;
  }
  button.btn-back{
      width: 260px;
      height: 52px;
      background: #62C3D0;
      border-radius: 50px;
      color: #fff;
      border:none;
  }

  @media only screen and (min-width: 980px) {
    img.robot{
      margin-left: 70%;
      height: 280px;
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h1,p{
      text-align : center;
    }
    img.robot{
      margin-left: 0px !importants;
      height: 280px;
    }
  }
 
`;
const Bottom = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    margin: 80px;
    margin-top: 40px;
    margin-bottom: 0px;
    border-top: 1px solid #C4C4C4;
    h3{
      font-size: 18px;
    }
    .d-flex{
      display: flex;
      align-items: center;
      p{
        margin-left:20px;
        font-size: 16px;
      }
    }
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    @media only screen and (max-width: 900px) {
      flex-direction: column;
      margin: 20px;
      h3{
        margin-top:20px;
        margin-bottom:20px;
        text-align: center;
      }
      .d-flex{
        flex-direction: column;
      }
      position: relative;
    }
`;
const Section = styled.div`
    padding: 80px;
    padding-bottom: 0;
    @media only screen and (max-width: 600px) {
      padding: 0;
    }
`;
function NotFound() {
  const history = useHistory();

  const redirect = () => {
    history.push('/')
  }
  return (
    <Section>
      <Wrapper>
        <Col>
          <h1>Page Not Found</h1>
          <p>Sorry, we can’t find the page you’re looking for.</p>
          <button onClick={redirect} className="btn-back">Back to home</button>
        </Col>
        <Col className="mr-auto">
          <img className="robot" src={IcNotFound} alt="ic" />
        </Col>
      </Wrapper>
      <Bottom>
        <h3>Copyright 2020 PT Hamparan Cipta Karyatama</h3>
        <div className="d-flex">
          <p>About Us</p>
          <p>Privacy</p>
          <p>Cookies</p>
          <p>Terms</p>
          <p>Help</p>
        </div>
      </Bottom>
    </Section>
  )
}

export default NotFound;